.integration {
    animation: slide-in 0.3s ease;
  }

  /* IntegrationStyles.css */
  .integration-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This will push the action buttons to the bottom */
    height: 80vh;
    max-width: 700px;
    margin: 15px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    padding:10px;
  }
.stepper-container {
  flex-shrink: 0;
  padding: 16px 24px;
  background-color: #f5f5f5;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px !important;
  gap: 0.5;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card:hover {
  background-color: #f5f5f5;
}

.action-buttons {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid #e0e0e0;
  
}


/* You can maintain any existing styles for .text-input and simply add the .input-margin class for the margin */
TextField {
  width: 60%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}
/* Add more custom styles as needed */

.signing-options-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.signing-options-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.signing-options-group {
  margin-bottom: 20px;
}

.signing-option-input {
  margin-top: 10px;
}

.input-label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

.MuiTextField-root {
  margin-top: 8px;
}

.MuiFormControlLabel-root {
  display: block;
  margin: 10px 0;
}

.MuiRadio-root {
  color: #1976d2; /* Primary color for the radio button */
}

.MuiOutlinedInput-root {
  border-radius: 4px;
}

.MuiInputBase-input {
  padding: 12px;
}

.step-container {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Adjust the spacing between elements */
  margin-bottom: 20px; /* Space between each step */
}
.step-container img{
  position:relative;
  display:block;
  margin:10px auto;
}
.field-label {
  font-size: 16px; /* Larger font size for labels */
  font-weight: bold; /* Make labels bold */
  margin-bottom: 5px; /* Space between label and text field */
  color: #333; /* Darker color for better readability */
}

.text-field {
  --mui-outlined-input-padding: 8px; /* Reduce padding if necessary */
  --mui-outlined-input-notched-outline: 2px; /* Adjust border thickness */
}
