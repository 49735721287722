.api-component {
  font-family: Arial, sans-serif;
  margin: 20px;
  width: 100%;

}

.api-container {
  text-align: left; vertical-align: top;
  position: relative; padding:0px 25px;
 
}

.api-container h1 {
  transform: rotate(-90deg);
  transform-origin: top left;
  position: absolute;
  left: -30px; bottom: -50px;
  bottom:0; padding:10px;
  white-space: nowrap;
}

.generate-api-key {
  margin-bottom: 10px;
}

.api-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.api-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.api-key {
  font-family: monospace;
  font-size: 14px;
  padding: 5px;
  border-radius: 3px;
}

.api-secret {
  margin-left: 10px;
  font-size: 14px;
}

.api-button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}



.api-list-container {

  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
  display: inline-block;
}
