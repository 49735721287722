.login-container {
position: relative;
max-width:600px;
  margin: 10% auto;
  padding: 0px;
  display: flex;
  background:transparent;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.87);
    
  
    position: relative;
 
    overflow: visible;

}
.backdrop{
  height:100vh; width:100vw; background:#e9e9e9; top:0; position:absolute;
}
.videobg{
  position:absolute; top:0; left:0;
  width: 100%; 
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
  z-index:0;
}
.bg{
display: flex; min-width:300px;
  align-items: center;
  justify-content: center;
    
}

@media screen and (max-width: 440px) {
.login-container {
  display: flex;
  flex-direction: column; /* Stack child elements vertically *//* Set the container height to the full viewport height */
}
}